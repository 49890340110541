// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogi-ideasta-mobiilisovellukseksi-tsx": () => import("./../../../src/pages/blogi/ideasta-mobiilisovellukseksi.tsx" /* webpackChunkName: "component---src-pages-blogi-ideasta-mobiilisovellukseksi-tsx" */),
  "component---src-pages-blogi-mita-mobiilisovelluksen-kehittaminen-maksaa-tsx": () => import("./../../../src/pages/blogi/mita-mobiilisovelluksen-kehittaminen-maksaa.tsx" /* webpackChunkName: "component---src-pages-blogi-mita-mobiilisovelluksen-kehittaminen-maksaa-tsx" */),
  "component---src-pages-blogi-mobiilisovelluksen-julkaisu-tsx": () => import("./../../../src/pages/blogi/mobiilisovelluksen-julkaisu.tsx" /* webpackChunkName: "component---src-pages-blogi-mobiilisovelluksen-julkaisu-tsx" */),
  "component---src-pages-blogi-mobiilisovelluksen-ostajan-opas-tsx": () => import("./../../../src/pages/blogi/mobiilisovelluksen-ostajan-opas.tsx" /* webpackChunkName: "component---src-pages-blogi-mobiilisovelluksen-ostajan-opas-tsx" */),
  "component---src-pages-blogi-nettisivujen-ostajan-opas-tsx": () => import("./../../../src/pages/blogi/nettisivujen-ostajan-opas.tsx" /* webpackChunkName: "component---src-pages-blogi-nettisivujen-ostajan-opas-tsx" */),
  "component---src-pages-blogi-tsx": () => import("./../../../src/pages/blogi.tsx" /* webpackChunkName: "component---src-pages-blogi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innovaatioseteli-tsx": () => import("./../../../src/pages/innovaatioseteli.tsx" /* webpackChunkName: "component---src-pages-innovaatioseteli-tsx" */),
  "component---src-pages-iot-tsx": () => import("./../../../src/pages/iot.tsx" /* webpackChunkName: "component---src-pages-iot-tsx" */),
  "component---src-pages-kayttoliittymasuunnittelu-tsx": () => import("./../../../src/pages/kayttoliittymasuunnittelu.tsx" /* webpackChunkName: "component---src-pages-kayttoliittymasuunnittelu-tsx" */),
  "component---src-pages-kotisivut-tsx": () => import("./../../../src/pages/kotisivut.tsx" /* webpackChunkName: "component---src-pages-kotisivut-tsx" */),
  "component---src-pages-mobiilikehitys-tsx": () => import("./../../../src/pages/mobiilikehitys.tsx" /* webpackChunkName: "component---src-pages-mobiilikehitys-tsx" */),
  "component---src-pages-ohjelmistokehitys-ratkaisut-tsx": () => import("./../../../src/pages/ohjelmistokehitys-ratkaisut.tsx" /* webpackChunkName: "component---src-pages-ohjelmistokehitys-ratkaisut-tsx" */),
  "component---src-pages-ohjelmistokehitys-tsx": () => import("./../../../src/pages/ohjelmistokehitys.tsx" /* webpackChunkName: "component---src-pages-ohjelmistokehitys-tsx" */),
  "component---src-pages-palvelut-tsx": () => import("./../../../src/pages/palvelut.tsx" /* webpackChunkName: "component---src-pages-palvelut-tsx" */),
  "component---src-pages-toita-tsx": () => import("./../../../src/pages/toita.tsx" /* webpackChunkName: "component---src-pages-toita-tsx" */),
  "component---src-pages-web-kehitys-tsx": () => import("./../../../src/pages/web-kehitys.tsx" /* webpackChunkName: "component---src-pages-web-kehitys-tsx" */),
  "component---src-pages-yhteystiedot-tsx": () => import("./../../../src/pages/yhteystiedot.tsx" /* webpackChunkName: "component---src-pages-yhteystiedot-tsx" */)
}

